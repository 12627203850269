import { AsyncSelect } from "components";
import { useHttp } from "hooks";
import {putUser} from "shared/services";
import {
  getProjectLabel,
  getSelectDataSource,
} from "shared/utilities/common.util";
import { getProjects } from "shared/services";
import { Project } from "shared/models";
import { useFormik } from "formik";
import { Project_Validation } from "shared/utilities/validationSchema.util";


const ProjectSelect = ({history}) => {
  const { notify, requestHandler } = useHttp();

  let selected_project = {
    id: localStorage.getItem("project_id"),
    title: localStorage.getItem("project_title"),
    status: localStorage.getItem("project_status"),
    project_description: localStorage.getItem("project_description"),
  };
  const project = new Project(selected_project);

  const onProjectChange = async (project_instance) => {
    localStorage.removeItem("project_id");
    localStorage.removeItem("project_title");
    localStorage.removeItem("project_status");
    localStorage.removeItem("project_description");
    localStorage.setItem("project_id", project_instance.id);
    localStorage.setItem("project_title", project_instance.title);
    localStorage.setItem("project_status", project_instance.status);
    localStorage.setItem(
      "project_description",
      project_instance.project_description
    );
    try{
      const requestConfig = putUser({user: {"selected_project_id": project_instance.id}});
      await requestHandler(requestConfig, {loader: true});
      history.go();
    } catch (e){
      notify({
        msg: "Not able to set the project to default. Something went wrong!!",
        type: "error",
      });
    }
  };

  const getProjectsList = () =>
  new Promise((resolve, reject) => {
    const params = { per_page: 500, page_no: 1, sort: "created_at.desc" };
    getSelectDataSource(requestHandler, getProjects(params))
      .then((res) => resolve(res.data))
      .catch((error) => reject(error));
  });

  const { values } = useFormik({
    initialValues: project,
    validationSchema: Project_Validation,
  });

  return (
    <AsyncSelect
      id={"projects"}
      getOptionLabel={getProjectLabel}
      loadingMethod={getProjectsList}
      value={values}
      onChange={onProjectChange}
      // autowidth
      style={{width: '200px'}}
    />
  );
};

export default ProjectSelect;