class InspectionTemplate{
    constructor(props) {
      const {
        id = '',
        name = '',
        number = '',
        body = '',
        _destroy = false
      } = props || {};
      this.id = id;
      this.name = name;
      this.number = number;
      this.body = body;
    }
  }
  export {InspectionTemplate};