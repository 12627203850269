class InspectionTemplateType{
  constructor(props) {
    const {
      id = '',
      name = '',
      _destroy = false
    } = props || {};
    this.id = id;
    this.name = name;
  }
}
export {InspectionTemplateType};