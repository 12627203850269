const url = '/user_conversations';

const getUserConversations = (params) => ({url, method: 'get', params});

const getUserConversation = (id, params) => ({url: `${url}/${id}`, method: 'get', params});

const postUserConversation = (data) => ({url, method: 'post', data});


export {getUserConversations, getUserConversation, postUserConversation};
