const url = '/inspection_types';

const getInspectionTypes = (params) => ({url, method: 'get', params});

const getInspectionType = (id, params) => ({url: `${url}/${id}`, method: 'get', params});

const postInspectionType = (data) => ({url, method: 'post', data});

const putInspectionType = (id, data) => ({url: `${url}/${id}`, method: 'put', data});

export {getInspectionTypes, getInspectionType, postInspectionType, putInspectionType};
