class Meeting{
  constructor(props) {
    const {
      id = '',
      host_id = '',
      room_name = '',
      subject = '',
      meeting_timestamp = '',
      created_at = '',
      attendants = [],
      attendant_id = [],
      a =[],
      _destroy = false
    } = props || {};
    this.id = id;
    this.host_id = host_id;
    this.room_name = room_name;
    this.subject = subject;
    this.meeting_timestamp = meeting_timestamp;
    this.created_at = created_at;
    this.attendants = attendants;
    this.attendant_id = attendant_id;
  }
}
export {Meeting};