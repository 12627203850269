import { Group } from "./Group";
import { Member } from "./Member";

class Project{
  constructor(props) {
    const {
      id = '',
      title = '',
      status = '',
      project_description = '',
      groups = [],
      members = [],
      // _destroy = false
    } = props || {};
    this.id = id;
    this.title = title;
    this.status = status;
    this.project_description = project_description;
    this.groups = groups.map(groups => new Group(groups));
    this.members = members.map(members => new Member(members));
  }
}
export {Project};