import MuiChip from "./MuiChip";
import MuiFormLabel from "./MuiFormLabel";
import {MuiTableCell, MuiTablePagination} from "./MuiTable";

const overrides = {
    MuiChip,
    MuiFormLabel,
    MuiTableCell,
    MuiTablePagination
};

export default overrides;
