class MemoReply{
  constructor(props) {
    const {
      id = '',
      content = '',
      user_memo_id = '',
      created_at ='',
      _destroy = false
    } = props || {};
    this.id = id;
    this.content = content;
    this.user_memo_id = user_memo_id;
    this.created_at = created_at;
  }
}
export {MemoReply};