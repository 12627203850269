class Transmittal{
  constructor(props) {
    const {
      id = '',
      subject = '',
      sender = '',
      sender_id = '',
      recipients = [],
      uploads = [],
      // _destroy = false
    } = props || {};
    this.id = id;
    this.subject = subject;
    this.sender = sender;
    this.sender_id = sender_id;
    this.recipients = recipients;
    this.uploads = uploads;
  }
}
export {Transmittal};