class UserInspectionSheet{
  constructor(props) {
    const {
      id = '',
      body = '',
      answers = '',
      created_at ='',
      longitude = '',
      latitude = '',
      location = '',
      inspected_at = '',
      approved = '',
      inspection_sheet_id = '',
      name = '',
      images = [],
      drawing = '',
      video = '',
      project = '',
      _destroy = false
    } = props || {};
    this.id = id;
    this.body = body;
    this.answers = answers;
    this.created_at = created_at;
    this.longitude = longitude;
    this.latitude = latitude;
    this.inspected_at = inspected_at;
    this.approved = approved;
    this.inspection_sheet_id = inspection_sheet_id;
    this.name = name;
    this.location = location;
    this.images = images;
    this.drawing = drawing;
    this.video = video;
    this.project = project;
  }
}
export {UserInspectionSheet};