const url = '/user_memos';
const getSentMemos = (params) => ({url: `${url}/get_sent_memo`, method: 'get', params});
const getRecievedMemos = (params) => ({url: `${url}/get_recieved_memo`, method: 'get', params});

const getMemo = (id) => ({url: `${url}/${id}`, method: 'get'});
const putMemo = (id, payload) => ({url: `${url}/${id}`, method: 'put', data: payload});
const postMemo = (payload) => ({url, method: 'post', data: payload});
const deleteMemo = (payload) => ({url, method: 'delete', data: payload});

export { getSentMemos, getMemo, postMemo, deleteMemo, getRecievedMemos, putMemo };
