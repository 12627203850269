const url = '/user_storages';

const getUserStorages = (id, params) => ({url: `${url}/${id}`, method: 'get', params});
const getUserStorage = (id, params) => ({url: `${url}/${id}`, method: 'get', params});

const postUserStorage = (data) => ({url, method: 'post', data});

const putUserStorage = (id, data) => ({url: `${url}/${id}`, method: 'put', data});
const userStorageGenerateDownloadLink = (id) => ({url: `${url}/${id}/generate_link`, method: 'get'});

const deleteUserStorage = (id) => ({url: `${url}/${id}`, method: 'delete'});

export { getUserStorage, getUserStorages, postUserStorage, putUserStorage, deleteUserStorage, userStorageGenerateDownloadLink };