const url = '/transmittals';

const getSentTransmittals = (params) => ({ url, method: 'get', params });

const getSentTransmittal = (params) => ({ url: `${url}/get_sent_transmittals`, method: 'get', params });

const getRecievedTransmittals = (params) => ({ url: `${url}/get_recieved_transmittals`, method: 'get', params });

const getTransmittal = (id, params) => ({ url: `${url}/${id}`, method: 'get', params });

const postTransmittal = (data) => ({ url, method: 'post', data ,headers: { processData: "false" }});


export { getSentTransmittals, getTransmittal, getSentTransmittal, getRecievedTransmittals, postTransmittal };
