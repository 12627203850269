import loadable from "@loadable/component";
// Dashboard
const Dashboard = loadable(() => import("../pages/Dashboard"));
const Home = loadable(() => import("../pages/Home"));
const STORAGE = loadable(() => import("../pages/Storage/Storage"));
const FILES = loadable(() => import("../pages/FilesAndFolders"));
const CALENDAR = loadable(() => import("../pages/Calendar"));
const MAP = loadable(() => import("../components/GoogleMapComponent"));
// const PDFVIEW = loadable(() => import("../pages/Inspection/Components/PdfViewer"));

const INSPECTION = {
  ALL: loadable(() => import("../pages/Inspection")),
  NEW: loadable(() => import("../pages/Inspection/NewSheet")),
};

const TRANSMITTALS = {
  ALL: loadable(() => import("../pages/DocumentManager/Transmittals/ViewAllTransmittals")),
  NEW: loadable(() => import("../pages/DocumentManager/Transmittals/CreateUpdateTransmittals")),
  VIEW: loadable(() => import("../pages/DocumentManager/Transmittals/CreateUpdateTransmittals")),
};

const INSPECTIONTEMPLATETYPE ={
  ALL: loadable(()=> import("../pages/InspectionTemplateType/ViewAllInspectionTemplateTypes")),
  NEW: loadable(()=> import("../pages/InspectionTemplateType/CreateUpdateInspectionTemplateType")),
  EDIT: loadable(()=> import("../pages/InspectionTemplateType/CreateUpdateInspectionTemplateType")),
};

const INSPECTIONMAPVIEW ={
  ALL: loadable(()=> import("../pages/Inspection/InspectionMap/ViewInspectionMap")),
};

const USERINSPECTION = {
  NEW: loadable(() => import("../pages/Inspection/UseInspectionSheet")),
  VIEW: loadable(() => import("../pages/Inspection/ViewUserInspectionSheet")),
  EDIT: loadable(() => import("../pages/Inspection/EditUserInspectionSheet")),
}

const PROJECTINFORMTAION = {
  VIEW: loadable(() => import("../pages/ProjectInformation/Index")),
}

const CONTACT = {
  ALL: loadable(() => import("../pages/Contact/ViewAllContact")),
  VIEW: loadable(() => import("../pages/Contact/ViewContact")),
  NEW: loadable(() => import("../pages/Contact/CreateUpdateContact")),
  EDIT: loadable(() => import("../pages/Contact/CreateUpdateContact")),
};

const PROJECT = {
  ALL: loadable(() => import("../pages/Project/ViewAllProject")),
  VIEW: loadable(() => import("../pages/Project/ViewProject")),
  NEW: loadable(() => import("../pages/Project/CreateUpdateProject")),
  EDIT: loadable(() => import("../pages/Project/CreateUpdateProject")),
};

const GROUP = {
  ALL: loadable(() => import("../pages/Group/ViewAllGroup")),
  VIEW: loadable(() => import("../pages/Group/ViewGroup")),
  NEW: loadable(() => import("../pages/Group/CreateUpdateGroup")),
  EDIT: loadable(() => import("../pages/Group/CreateUpdateGroup")),
};

const MEMO = {
  ALL: loadable(() => import("../pages/Communication/Memo/ViewAllMemo")),
  VIEW: loadable(() => import("../pages/Communication/Memo/ViewMemo")),
  NEW: loadable(() => import("../pages/Communication/Memo/CreateMemo")),
};

const MEMOTEMPLATE = {
  ALL: loadable(() =>
    import("../pages/Communication/MemoTemplate/ViewAll")
  ),
  VIEW: loadable(() =>
    import("../pages/Communication/MemoTemplate/CreatorPages/ViewMemoTemplate")
  ),
  NEW: loadable(() =>
    import(
      "../pages/Communication/MemoTemplate/CreatorPages/CreateUpdateMemoTemplate"
    )
  ),
  EDIT: loadable(() =>
    import(
      "../pages/Communication/MemoTemplate/CreatorPages/CreateUpdateMemoTemplate"
    )
  ),
};

const INSPECTIONTEMPLATE = {
  ALL: loadable(() => import("../pages/Inspection/Components/NewTemplate")),
  VIEW: loadable(() =>
    import("../pages/Inspection/CreatorPages/ViewInspectionTemplate")
  ),
  NEW: loadable(() =>
    import("../pages/Inspection/CreatorPages/CreateUpdateMemoTemplate")
  ),
  EDIT: loadable(() =>
    import("../pages/Inspection/CreatorPages/EditInspectionTemplate")
  ),
  PDF: loadable(() =>
    import("../pages/Inspection/PdfView")
  ),
};

const COMMUNICATION = {
  ALL: loadable(() => import("../pages/Communication/CommunicationIndex")),
  CHAT: loadable(() => import("../pages/Communication/Chat/Messaging")),
};

const DOCUMENTMANAGER = {
  ALL: loadable(() => import("../pages/DocumentManager/index")),
};

const MEETING = {
  ALL: loadable(() => import("../pages/Communication/Meeting/ViewAllMeetings")),
  NEW: loadable(() => import("../pages/Communication/Meeting/CreateUpdateMeeting")),
  VideoMeeting: loadable(() => import("../pages/Communication/Meeting/VideoMeeting")),
  View: loadable(() => import("../pages/Communication/Meeting/CreateUpdateMeeting")),
};

const PDFVIEW = {
  ALL: loadable(() => import("../pages/Inspection/Components/PdfViewer")),
};

export const siteOwnerPages = [
  { id: "contact", path: "/contacts", component: CONTACT.VIEW },
  { id: "contact_edit", path: "/contact/edit", component: CONTACT.EDIT },
  { id: "contact_new", path: "/contact/new", component: CONTACT.NEW },

  { id: "project", path: "/projects", component: PROJECT.ALL },
  { id: "project", path: "/project/view", component: PROJECT.VIEW },
  { id: "project_edit", path: "/project/edit", component: PROJECT.EDIT },
  { id: "project_new", path: "/project/new", component: PROJECT.NEW },

  { id: "group", path: "/groups", component: GROUP.ALL },
  { id: "group_view", path: "/group/view", component: GROUP.VIEW },
  { id: "group_edit", path: "/group/edit", component: GROUP.EDIT },
  { id: "group_new", path: "/group/new", component: GROUP.NEW },

  { id: "user_inspection_new", path: "/user_inspection/new", component: USERINSPECTION.NEW },
  { id: "user_inspection_view", path: "/user_inspection/view", component: USERINSPECTION.VIEW },
  { id: "user_inspection_edit", path: "/user_inspection/edit", component: USERINSPECTION.EDIT },
  { id: "project_information", path: "/projectInformation", component: PROJECTINFORMTAION.VIEW },
  {id: "inspection_template_type_all", path: "/inspection_template_types", component: INSPECTIONTEMPLATETYPE.ALL},
  {id: "inspection_template_type_new", path: "/inspection_template_type/new", component: INSPECTIONTEMPLATETYPE.NEW},
  {id: "inspection_template_type_edit", path: "/inspection_template_type/edit", component: INSPECTIONTEMPLATETYPE.EDIT},
  {id: "inspection_map_view", path: "/inspection_mapview", component: INSPECTIONMAPVIEW.ALL},
  {id: "chat", path: "/chat", component: COMMUNICATION.CHAT},
  {id: "video_meeting", path: "/video_meeting", component: MEETING.ALL},
  {id: "new_video_meeting", path: "/video_meeting/new", component: MEETING.NEW},
  {id: "meeting", path: "/video_meeting/meeting", component: MEETING.VideoMeeting},
  {id: "meeting_view", path: "/video_meeting/view", component: MEETING.View},
  {id: "transmittals", path: "/transmittals", component: TRANSMITTALS.ALL},
  {id: "transmittals_new", path: "/transmittals/new", component: TRANSMITTALS.NEW},
  {id: "transmittals_view", path: "/transmittals/view", component: TRANSMITTALS.VIEW},
  {id: "docs_manager_all", path: "/docs_manager", component: DOCUMENTMANAGER.ALL},
];

export const projectAdminPages = [
  { id: "contact", path: "/contacts", component: CONTACT.VIEW },
  { id: "contact_edit", path: "/contact/edit", component: CONTACT.EDIT },
  { id: "contact_new", path: "/contact/new", component: CONTACT.NEW },

  { id: "project", path: "/projects", component: PROJECT.ALL },
  { id: "project", path: "/project/view", component: PROJECT.VIEW },
  { id: "project_edit", path: "/project/edit", component: PROJECT.EDIT },
  { id: "project_new", path: "/project/new", component: PROJECT.NEW },

  { id: "group", path: "/groups", component: GROUP.ALL },
  { id: "group_view", path: "/group/view", component: GROUP.VIEW },
  { id: "group_edit", path: "/group/edit", component: GROUP.EDIT },
  { id: "group_new", path: "/group/new", component: GROUP.NEW },
  { id: "user_inspection_new", path: "/user_inspection/new", component: USERINSPECTION.NEW },
  { id: "user_inspection_view", path: "/user_inspection/view", component: USERINSPECTION.VIEW },
  { id: "user_inspection_edit", path: "/user_inspection/edit", component: USERINSPECTION.EDIT },
  { id: "project_information", path: "/projectInformation", component: PROJECTINFORMTAION.VIEW },
  { id: "pdf_view", path: "/pdf/view", component: INSPECTIONTEMPLATE.PDF },
  { id: "map", path: "/map", component: MAP },
  {id: "inspection_template_type_all", path: "/inspection_template_types", component: INSPECTIONTEMPLATETYPE.ALL},
  {id: "inspection_template_type_new", path: "/inspection_template_type/new", component: INSPECTIONTEMPLATETYPE.NEW},
  {id: "inspection_template_type_edit", path: "/inspection_template_type/edit", component: INSPECTIONTEMPLATETYPE.EDIT},
  {id: "inspection_map_view", path: "/inspection_mapview", component: INSPECTIONMAPVIEW.ALL},
  {id: "pdf", path: "/pdf", component: PDFVIEW.ALL},
  {id: "chat", path: "/chat", component: COMMUNICATION.CHAT},
  {id: "video_meeting", path: "/video_meeting", component: MEETING.ALL},
  {id: "new_video_meeting", path: "/video_meeting/new", component: MEETING.NEW},
  {id: "meeting", path: "/video_meeting/meeting", component: MEETING.VideoMeeting},
  {id: "meeting_view", path: "/video_meeting/view", component: MEETING.View},
  {id: "transmittals", path: "/transmittals", component: TRANSMITTALS.ALL},
  {id: "transmittals_new", path: "/transmittals/new", component: TRANSMITTALS.NEW},
  {id: "transmittals_view", path: "/transmittals/view", component: TRANSMITTALS.VIEW},
  {id: "docs_manager_all", path: "/docs_manager", component: DOCUMENTMANAGER.ALL},
];

export const memberPages = [
  { id: "dashboard", path: "/dashboard", component: Dashboard },
  { id: "files", path: "/files", component: FILES },
  { id: "home", path: "/home", component: Home },
  {
    id: "communications",
    path: "/communications",
    component: COMMUNICATION.ALL,
  },

  { id: "storage", path: "/storages", component: STORAGE },
  { id: "calendar", path: "/calendar", component: CALENDAR },
  { id: "inspection", path: "/inspection", component: INSPECTION.ALL },
  {
    id: "new_inspection_sheet",
    path: "/inspection/new",
    component: INSPECTION.NEW,
  },
  {
    id: "inspection_template_new",
    path: "/inspection_template/new",
    component: INSPECTIONTEMPLATE.NEW,
  },
  {
    id: "inspection_template_all",
    path: "/inspection_template/all",
    component: INSPECTIONTEMPLATE.ALL,
  },
  {
    id: "inspection_template_edit",
    path: "/inspection_template/edit",
    component: INSPECTIONTEMPLATE.EDIT,
  },
  {
    id: "inspection_template_view",
    path: "/inspection_template/view",
    component: INSPECTIONTEMPLATE.VIEW,
  },

  {
    id: "memo_template_new",
    path: "/memo_template/new",
    component: MEMOTEMPLATE.NEW,
  },
  {
    id: "memo_template_all",
    path: "/memo_template/all",
    component: MEMOTEMPLATE.ALL,
  },
  {
    id: "memo_template_edit",
    path: "/memo_template/edit",
    component: MEMOTEMPLATE.EDIT,
  },
  {
    id: "memo_template_view",
    path: "/memo_template/view",
    component: MEMOTEMPLATE.VIEW,
  },

  { id: "memo_new", path: "/memo/new", component: MEMO.NEW },
  { id: "memo_all", path: "/memo/all", component: MEMO.ALL },
  { id: "memo_view", path: "/memo/view", component: MEMO.VIEW },

  { id: "user_inspection_edit", path: "/user_inspection/edit", component: USERINSPECTION.EDIT },
  {id: "inspection_template_type_all", path: "/inspection_template_types", component: INSPECTIONTEMPLATETYPE.ALL},
  {id: "inspection_template_type_new", path: "/inspection_template_type/new", component: INSPECTIONTEMPLATETYPE.NEW},
  {id: "inspection_template_type_edit", path: "/inspection_template_type/edit", component: INSPECTIONTEMPLATETYPE.EDIT},
  {id: "inspection_map_view", path: "/inspection_mapview", component: INSPECTIONMAPVIEW.ALL},
  {id: "chat", path: "/chat", component: COMMUNICATION.CHAT},
  {id: "video_meeting", path: "/video_meeting", component: MEETING.ALL},
  {id: "new_video_meeting", path: "/video_meeting/new", component: MEETING.NEW},
  {id: "meeting", path: "/video_meeting/meeting", component: MEETING.VideoMeeting},
  {id: "meeting_view", path: "/video_meeting/view", component: MEETING.View},
  {id: "transmittals", path: "/transmittals", component: TRANSMITTALS.ALL},
  {id: "transmittals_new", path: "/transmittals/new", component: TRANSMITTALS.NEW},
  {id: "transmittals_view", path: "/transmittals/view", component: TRANSMITTALS.VIEW},
  {id: "docs_manager_all", path: "/docs_manager", component: DOCUMENTMANAGER.ALL},
];
