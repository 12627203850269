const url = '/user_inspection_sheets';

const getUserInspectionSheets = (params) => ({url, method: 'get', params});
const getUserInspectionSheetGenerateAllCsv = (params) => ({url: `${url}/generate_all_csv`, method: 'get', params});
const getUserInspectionSheet = (id, params) => ({url: `${url}/${id}`, method: 'get', params});
const postUserInspectionSheet = (data) => ({url, method: 'post', data});
const putUserInspectionSheet = (id, data) => ({url: `${url}/${id}`, method: 'put', data});
const deleteUserInspectionSheet = (id) => ({url: `${url}/${id}`, method: 'delete'});
const downloadResources = (id) => ({url: `${url}/${id}/download_resources`, method: 'get'});
const getInspectionSheetAllPdf = (params) => ({url: `${url}/generate_all_pdf`, method: 'get', params});

export { getUserInspectionSheets, getUserInspectionSheet, postUserInspectionSheet, putUserInspectionSheet, deleteUserInspectionSheet, getUserInspectionSheetGenerateAllCsv, downloadResources, getInspectionSheetAllPdf };